import { DiscussionEmbed } from "disqus-react"
import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../ui/Container"
import {
  Article,
  PostFooter,
  PostHeader,
  PostNav,
  PostSubheaderLine,
  PostTitle,
  PostUpdateInfoLine,
  TagsLine,
} from "../ui/post"
import styled from "@emotion/styled"

const CommentsSection = styled.div`
  margin-top: 32px;
`

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const { previous, next } = pageContext
  const { html, excerpt, frontmatter, fields } = post
  const { title, description, posted, updated, tags } = frontmatter
  const { slug } = fields

  return (
    <Layout>
      <SEO title={title} description={description || excerpt} />
      <Container>
        <main>
          <Article>
            <PostHeader>
              <PostTitle>{title}</PostTitle>
              <PostSubheaderLine posted={posted} />
              {updated && <PostUpdateInfoLine updated={updated} />}
            </PostHeader>
            <section dangerouslySetInnerHTML={{ __html: html }} />
            <PostFooter>
              {tags.length > 0 && <TagsLine tags={tags} />}
              <PostNav
                previousLink={previous && `/blog${previous.fields.slug}`}
                nextLink={next && `/blog${next.fields.slug}`}
              />
            </PostFooter>
          </Article>

          <CommentsSection>
            <DiscussionEmbed
              shortname="danielfriesensblog"
              config={{
                url: "https://danf.ca/blog" + slug,
                identifier: slug.replace(/^\/?/, "").replace(/\/$/, ""),
                title,
              }}
            />
          </CommentsSection>
        </main>
      </Container>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug }, sourceName: { eq: "posts" } }
    ) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        posted
        updated
        tags
      }
      fields {
        slug
      }
    }
  }
`
